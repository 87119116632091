<template>
    <!-- 会员服务 -->
    <div class="introduce">
        <topHeader></topHeader>
        <div class="intro_content">
            <el-tabs tab-position="left" v-model="activeName">
				<el-tab-pane label="忘记密码" name="forget">
                    <div class="tab_nav">首页 > <span>忘记密码</span></div>
                    <div class="tab_text">
                        <div class="login_con">
                            <div>
                                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm">
  								    <el-form-item prop="email">
    								    <el-input type="text" v-model="ruleForm.email" placeholder='邮箱' autocomplete="off">
										    <i slot="prefix" class="name_png"></i>
									    </el-input>
  								    </el-form-item>
  								    <el-form-item prop="password">
    								    <el-input type="password" v-model="ruleForm.password" placeholder='新密码' autocomplete="off">
										    <i slot="prefix" class="pass_png"></i>
									    </el-input>
  								    </el-form-item>
                                    <el-form-item prop="password_confirmation">
    								    <el-input type="password" v-model="ruleForm.password_confirmation" placeholder='确认密码' autocomplete="off">
										    <i slot="prefix" class="pass_png"></i>
									    </el-input>
  								    </el-form-item>
  								    <el-form-item  class="code" prop="code">
									    <el-input class="yanzhen" placeholder='验证码' v-model="ruleForm.code">
										    <i slot="prefix" class="num_png"></i>
									    </el-input>
									    <!-- <canvas id="canvas" width="96px" height="44px" @click="handleCanvas"> </canvas> -->
                                        <div class="send_code" @click="sendCode">{{ getCodeText }}</div>
  								    </el-form-item>
								    <el-form-item>
									    <el-button @click="register('ruleForm')" class="free" type="primary">修改密码</el-button>
								    </el-form-item>
							    </el-form>
                            </div>
						</div>
                    </div>
                </el-tab-pane>
            </el-tabs>
        </div>
        <foot></foot>
    </div>
</template>

<script>
	import axios from '../http/axios';
    export default {
        data(){
            var checkCode = (rule, value, callback) => {
        		if (!value) {
          			return callback(new Error('验证码不能为空'));
        		}
                callback()
        		// setTimeout(() => {
          		// 	if(value != this.true_code){
				// 		return callback(new Error('验证码错误'))
				// 	}
        		// });
      		}
            var checkPassword = (rule,value,callback)=>{
                if (!value) {
          			return callback(new Error('请再次输入密码'));
        		}
                if(value != this.ruleForm.password){
                    return callback(new Error('两次密码不一致'))
                }
                callback()
            }
            var checkEmail = (rule,value,callback)=>{
                if (!value) {
          			return callback(new Error('请输入邮箱'));
        		}
                if(!this.validateEmail(value)){
                    return callback(new Error('请输入正确的邮箱格式'))
                }
                callback()
            }
            return{
                ruleForm: {
         			email: '',
          			password:'',
                    password_confirmation:'',
					code:'',
       			},
                //校验
                rules: {
                    email: [
                        { validator: checkEmail, trigger: 'blur' }
          			],
          			password: [
            			{ required:true , message:'请输入密码', trigger: 'blur' }
          			],
          			code: [
            			{ validator: checkCode, trigger: 'blur' }
          			],
                    password_confirmation:[
                        { validator: checkPassword, trigger: 'blur' }
                    ]
        		},
                activeName:'forget',
                //验证码
				true_code:'',
				yanzheng_arr:[],
                getCodeText:'发送验证码'
            }
        },
        mounted(){
            this.getCodeText = '发送验证码'
        },
        methods:{
            isPhoneNumber(phoneNumber) {
                const regExp = /^1[3-9]\d{9}$/;
                return regExp.test(phoneNumber);
            },
            validateEmail(email) {
                const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                return emailRegex.test(email);
            },
            sendCode(){
				if(this.getCodeText != '发送验证码'){
					return
				}
				if(this.validateEmail(this.ruleForm.email)){
					axios.post('send/register/code',{email:this.ruleForm.email}).then(res=>{
                        if(res.err_code == 200){
                                this.getCodeText = '60'
                                let time = setInterval(()=>{
                                    this.getCodeText--
                                    if(this.getCodeText == 0){
                                        clearInterval(time)
                                        this.getCodeText = '发送验证码'
                                    }
                                },1000)
                                this.$message({
                                    message:res.msg,
                                    type: 'success'
                                })
                            }else{
                                this.$message.error(res.msg)
                            }
					}).catch(e=>{
						console.log(e);
					})
				}else{
					return
				}
			},
            register(formName){
                this.$refs[formName].validate((valid) => {
          			if (valid) {
           				axios.post('auth/update/pass',this.ruleForm).then(res=>{
                            if(res.err_code == 200){
                                this.$message({
                                    message:res.msg,
                                    type: 'success'
                                })
                                this.$router.push('/')
                            }else{
                                this.$message.error(res.msg)
                            }
                        })
          			} else {
            			console.log('error submit!!');
            			return false;
          			}
        		});
            },           
        }
    }
</script>

<style scoped lang='less'>
.introduce{
    .intro_content{
        width: 1140px;
		min-width: 1140px;
        min-height: 517px;
        margin: 20px auto 40px;
        /deep/.el-tabs__active-bar,/deep/.el-tabs--left .el-tabs__nav-wrap.is-left::after{
            display: none;
        }
        /deep/.el-tabs--left .el-tabs__header.is-left{
            margin-right: 26px;
        }
        /deep/.el-tabs__item{
            width: 270px;
            height: 52px;
            color: #999999;
            font-size: 16px;
            line-height: 52px;
            padding: 0 27px;
            text-align: left;
            border: 1px solid #EBEBEB;
        }
        /deep/.el-tabs__item+.el-tabs__item{
            border-top: 0;
        }
        /deep/.el-tabs__item.is-active{
            background-color:#1D7FD7;
            color: #ffffff;
        }
        .tab_nav{
            font-size: 16px;
            padding-bottom: 8px;
            border-bottom: 2px solid #1D7FD7;
            span{
                color: #1D7FD7;
            }
        }
        .tab_text{
            margin-top: 28px;
            .title{
                font-size: 24px;
                color: #1D7FD7;
                line-height: 24px;
                margin-bottom: 19px;
            }
            >div{
                font-size: 14px;
                line-height: 24px;
                margin-bottom: 22px;
                >div{
                    margin-left: 22px;
                }
            }
            .submit{
                >div{
                    margin-left: 0;
                    display: flex;
                    align-items: center;
                }
                a{
                    font-size: 16px;
                    color: #333333;
                }
                i{
                    display: inline-block;
                    width: 18px;
                    height: 16px;
                    background-image: url('../assets/image/service/附件.png');
                    margin-right: 11px;
                }
            }
        }
        .login_con{
			padding: 0 20px;
            display: flex;
            justify-content: center;
            >div{
				// /deep/.el-form{
				// 	margin-top: 20px;
				// }
				/deep/.el-form-item{
					margin-bottom: 20px;
				}
				// /deep/.el-form-item:last-child,/deep/.el-form-item:nth-child(4){
				// 	margin-bottom: 0;
				// }
				/deep/.el-input__inner{
					position: relative;
					border: 0;
                    border: 1px solid #EBEBEB;
					// width: 290px;
					height: 46px;
					border-radius: 4px;
					padding-left: 43px;
				}
				/deep/.el-button{
					width: 290px;
					height: 44px;
					color: #fff;
					font-size: 16px;
					background-color: #1D7FD7;
					border-radius: 4px;
				}
				/deep/.el-form-item__error{
					font-size: 14px;
					color: #FF1821;
				}
				.code{
					/deep/.el-form-item__content{
						display: flex;
					}
					.yanzhen{
						width: 186px;
						margin-right: 8px;
					}
                    .send_code{
                        cursor: pointer;
                        width: 96px;
                        line-height: 46px;
                        border-radius: 4px;
                        background-color: #1D7FD7;
                        color: #FFFFFF;
                        text-align: center;
                    }
				}
				i{
					position: absolute;
					top: 16px;
					left: 14px;
					width: 16px;
					height: 16px;
                    background-repeat: no-repeat;
				}
				.name_png{
					background-image: url('../assets/image/homePage/email.png');
				}
				.pass_png{
					background-image: url('../assets/image/homePage/pass.png');
				}
				.num_png{
					background-image: url('../assets/image/homePage/yanzheng.png');
				}
				#canvas{
					height: 44px;
					border-radius: 4px;
					border: 1px solid #C3D4E3;
					cursor: pointer;
				}
		    }
        }
    }
}
</style>